<template>
  <div id="map">
  <!--In the following div the HERE Map will render-->
    <div id="mapContainer" style="height:500px;width:100%" ref="hereMap"></div>
  </div>
</template>

<script>
export default {
  name: "HereMap",
  props: {
    center: Object,
  },
  data() {
    return {
      platform: null,
      apikey: "lg9cr1YnEguawAD64JsZG9b797HqLryfKVjDits9LGM"
      // You can get the API KEY from developer.here.com
    };
  },
  async mounted() {
    // Initialize the platform object:
    const platform = new window.H.service.Platform({
      apikey: this.apikey
    });
    this.platform = platform;
    this.initializeHereMap();
  },
  methods: {
    
    initializeHereMap() { // rendering map

      const mapContainer = this.$refs.hereMap;
      const H = window.H;
      // Obtain the default map types from the platform object
      var maptypes = this.platform.createDefaultLayers();

      // Instantiate (and display) a map object:
      var map = new H.Map(mapContainer, maptypes.vector.normal.map, {
        zoom: 15,
        center: this.center,
      });

      addEventListener("resize", () => map.getViewPort().resize());

      // add behavior control
      var behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
      behavior.disable(H.mapevents.Behavior.WHEELZOOM);
      // new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
      // H.mapevents.Behavior(H.mapevents.Behavior.WHEELZOOM);
      // add UI
      H.ui.UI.createDefault(map, maptypes);
      // End rendering the initial map
      this.addMarkersToMap(map)
    },
    addMarkersToMap(map) {
    const H = window.H;
    var prokupljeMarker = new H.map.Marker({lat:43.24485530687062, lng:21.5984692132037});
    map.addObject(prokupljeMarker);
    }
  }
};
</script>

<style scoped>
#map {
  width: 100%;
  text-align: center;
  border: 1px solid #4D81C0;
  background-color: #ccc;
}
</style>