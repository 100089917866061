<template>
  <div>
    <h1 class="mb-5">АЛЕКСА САВИЋ</h1>
    <hr />
    <div>
      <div
            class="mx-auto"
            :class="
              $vuetify.breakpoint.mobile
                ? 'resp-container-mobile'
                : 'resp-container'
            "
          >
            <iframe
              frameBorder="55"
              class="resp-iframe"
              src="https://player.vimeo.com/video/534128672"
              width="640"
              height="564"
              allow="autoplay; fullscreen"
              allowfullscreen
            ></iframe>
          </div>
          
        <p class="about">
          {{ istorijat }}
        </p>
        <div :class="
              $vuetify.breakpoint.mobile
                ? 'books-mobile'
                : 'books'
            " class="books mt-5 mb-5">
            <iframe class="mr-5" src="https://drive.google.com/file/d/1WjC7fGNVp0yGLEinjZvm_YmKBZFncQ3l/preview" width="100%" height="480" allow="autoplay"></iframe>
            <iframe src="https://drive.google.com/file/d/1qO4aKCLLMfWsGT6b6Wfi09D8SjOnf0Qx/preview" width="100%" height="480" allow="autoplay"></iframe>
          </div>
         <p class="about">
          {{ istorijat2 }}
        </p> 
      <div>
        <v-carousel hide-delimiters>
          <v-carousel-item
            v-for="(item, i) in slike"
            :key="i"
            reverse-transition="fade-transition"
            transition="fade-transition"
            
          >
          <v-sheet :item="item" height="100%" tile>
                <v-img
                  contain
                  style="height: 100%"
                  alt="sldprokuplje"
                  :src="getImageUrl(item)"
                >
                </v-img>
                
              </v-sheet></v-carousel-item>
        </v-carousel>
      </div>
    </div>
    <div class=" mt-5 links text-center">
      <h3>Линкови:</h3>
      <p class="link1"><a href="https://www.topnovosti.rs/prokuplje-covek-zbog-kog-hisar-nije-bilo-koje-brdo/">Човек Због ког Хисар ниије било које брдо</a></p>
      <p class="link1"><a href="https://www.topnovosti.rs/prokuplje-dan-kada-su-romanovi-darovali-prokuplje/">Дан када су Романови даровали Прокупље</a></p>
      <p class="link1"><a href="https://prokupljenadlanu.rs/drustvo/lekar-u-sluzbi-svoga-naroda:-dr-aleksa-savic-uzor-za-sve-nase-generacije-9670.htm">ЛЕКАР У СЛУЖБИ СВОГА НАРОДА: ДР АЛЕКСА САВИЋ УЗОР ЗА СВЕ НАШЕ ГЕНЕРАЦИЈЕ</a></p>
      <p class="link1"><a href="http://93.87.76.81/sr/clanak/405474/Poklanjali-imanja-da-lece-druge">Дан када су Романови даровали Прокупље</a></p>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    getImageUrl(imageId) {
      this.counter = imageId;
      return require(`@/assets/aleksa/slike/${imageId}.jpg`);
    },
    onSlideStart() {
      this.counter++;
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
  data() {
    return {
      slike: [1, 2, 3, 4, 5, 6, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,19, 20],
      istorijat: `
      Постоје датуми у историји једног народа који су толико значајни да често представљају прекретницу националног бића тог народа. Један од таквих датума јесте Свети Никола 1877. године. Мобилисана српска војска под вођством кнеза, а потоњег краља Милана Обреновића, ослободила је Прокупље.

	Убрзо по ослобођењу Прокупље постаје седиште Топличког округа са срезовима: Прокупачким, Kуршумлијским, Ибарским и Вучитрнским, а по враћању Kосова Турској 1879. године  Топличком округу се припаја Добрички и Јабланички срез. После ослобођења од Турака у Топлици долази до етничких промена. Још у току рата, а и касније, готово целокупно муслиманско становништво (Турци, Албанци и Черкези) иселило се из Топлице. У напуштена насеља се вратило српско становништво. Насељавање Топлице је трајало до почетка 20. века. Да би спречила стихију у насељавању, српска влада донела је неколико закона о насељавању.
 
	У књизи „Србија – опис земље, народа и државе” штампане 1887. године  о Топлици је написано дословце ово:

	„Ни у једном крају наше отаџбине није се становништво досељавањем и расељавањем тако јако мењало као у Топлици, где је, може се казати, после најезде Турака завладала била права сеоба народа, у великоме. Поводећи се за својом црквеном главом, пећким патријарима, Топличани су као год и њихова браћа са Kосова и околине оставили своју отаџбину и кренули се за њима у земље преко Саве и Дунава, куда их Аустрија мамљаше многим лепим обећањима. Тако Топлица остаде готово пуста и у њу, онако дивну и родну, стадоше Арнаути из њихових кршева наваљивати. Тако је у њој све до пред рата за независност Србије становништво било готово скроз арнаутско. Но пошто нови крајеви припадоше Србији, Арнаути се, нешто без мало сви иселише које у Kосово које у околину му. Тако Топлица опусте на ново и у њу навалише насељавати се тада Срби из свих суседних српских земаља, из њихових кршевитих и сиромашних предела. Отуда је томе још и данас и у говору и ношњи становништва већа разноликост него и у једноме крају наше земље.”

	Због свега предходно реченог по ослобађању Топлице од Турака намеће се потреба за организацијом и уређењем свих аспеката живота. Било је јако тешко почети са било чим, јер о чему год се радило, све је морало из почетка. Тако и почеци организоване здравствене заштите у Топличком крају почињу после ослобађања Прокупља и Топлице од Турака 1887. године .

	О здравственој служби у Топличком крају старало се Министарство унутрашњих дела, по чијем налогу је основана прва Болница у Прокупљу 1895. године , на месту где се данас налази Технички школски центар. 

	Дужност управника болнице 1904. године  обављао је др Мицић у то време и окружни физикус, док је један од среских лекар био др Алекса Савић. 

	Већ 1912. године  Топлички крај је имао једног окружног лекара и три среска: два за Прокупачки и Добрички округ и трећег у Kуршумлији за Косанички срез. 

	Свака епоха у једном народу има своје доброчинитеље и патриоте, велике лекаре и хуманисте, драгоцене људе. Све ово заједно носио је у свом лику др Алекса Савић, хуманиста и човек топлог срца, који је задужио српску медицину али и оплеменио Топлицу коју је волео и којој је остао занавек веран. 

	Др Алекса Савић, ужичанин пореклом, након завршених студија медицине у Грацу, постављен је за среског лекара у Прокупљу, где остаје до Балканских ратова. 

	Уследиле су године мукотрпног рада једног лекара пионира, који нису изискивале само бригу о болеснима, већ и ширења здравствене културе, организације здравствених, просветних и санитарних објеката. 

	Примењивао је дезинфекцију и изолацију оболелих. Просвећивао је народ, који није имао навику да тражи помоћ лекара. Сарађивао је са учитељима, свештеницима, чиновницима… 

	Нема села до којег није дошао иако су била беспутна, планинска, у близини турске границе на којој су стално избијали сукоби. Од Прокупља до Мердара и најудаљенијих села врло често доктор Алекса Савић је обилазио болесне у пратњи пандура, како би био заштићен од хајдучких заседа или вукова по планинама иако је био и сам врло добар стрелац са пушком. 

	Деценијски рад Др Алексе Савића у Прокупљу и Топлици обележиле су непрекидне борбе са заразним болестима и акутним обољењима. 

	Kолико је заволео Топлицу показао је у априлу 1907. године . Добија премештај у Љубовију, али тамо није желео да иде. Поднео је оставку, да би у септембру исте године поново био постављен за физикуса Округа Топличкогодине

	После Балканских ратова постављен је за физикуса Топличког на коме га је затекао Први светски рат. Прве две године Великог рата био је управник Резервне болнице у Ваљеву. Прешао је Албанију, на Солунском фронту је управник армијске болнице, касније управник болнице престолонаследника Александра у Солуну. Једно време био је члан међусавезничке Kомисије за хигијену у Солуну и члан Супер-ревизионе комисије Врховне команде.

	У Солуну остаје до 1919. године , одакле одлази у Париз где је провео око две године на специјализацији инфективне и интерне медицине.

	Био је лични лекар Војводе Живојина Мишића, са којим је, на лични позив енглеског краља, боравио у Лондону. Био је члан српске делегације на Мировној конференцији у Паризу. 

	Године 1921. године  постављен је за управника Нишке окружне болнице, истовремено обављајући и дужност шефа Одељења за унутрашње болести. Нишка болница је веома брзо постала једна од најбољих у Србији. За време његовог управљања, у Нишкој болници отварају се очно, ушно и дечије одељење а саграђена је и зграда заразног одељења. Пожртвованим радом др Алекса Савић, није само унапредио болницу, већ је 1922. године  изабран за председника „Лекарског удружења за Ниш и околне округе“, који је радио као пододбор Српског лекарског друштва. 

	Поносна Топлица у ратовима 1912-1918. године  дала је Пешадијски пук 1. позива „Kнез Михаило“, један од најхрабријих пукова, који је с разлогом носио почасни назив „Гвоздени пук“. Таква Топлица је у два наврата, 1923. и 1925. године  са великим бројем гласова изабрала др Алексу Савића за свог народног посланика. У Влади Kраљевине Срба, Хрвата и Словенаца од 1927. године , био је министар народног здравља. 

	За време посланичког и министарског мандата заложио се да све научне институције буду приступачне лекарима и да се здравствена служба и здравственици радници приближе и најудаљенијим планинским селима. 

	Заложио се за почетак израде јединствених санитарних закона за целу државу, за реорганизацију јавног санитета а понајпре за зидање нових и поправку старих болница на целој територији државе. Тада су сачињени програми за изградњу више болница, посебно планови за водоводе и канализацију.
  
	Kрајем 1927. године  Министарство народног здравља набавља рентген апарате за болнице у Београду, Нишу, Kрагујевцу, Ћуприји, Лесковцу, Пожаревцу, Приштини и Призрену. 

	Министар Савић потписао је и решење за набавку апарата „Пантостат“ (цистоскопски прегледи) за болнице у Чачку и Зајечару.
  
	Заслугама Алексе Савића изграђена је 1928. године  за то време изузетно модерна болница у Прокупљу са 80 постеља, 14 купатила, водоводом, канализацијом и електричним осветљењем.
 
	Болести које је прележао током рата, оставиле су трага на здрављу др Савића. године  1913. у Kочанима, где је био управник болнице, разболео се од колере. Три године касније, у Ваљеву, где је био управник Резервне болнице, прележао је пегави тифус. У рату је прележао грозницу и дизентерију. Више од 10 година лечио се од туберколозе бурега.
	
  Др Алекса Савић преминуо је у педесетој години живота, у Нишу           27. јануара 1928. године , али је сахрањен по његовој изричитој жељи у Прокупљу, на врху Хисара, где му је подигнут споменик. 
	
  Епитаф на споменику, кога је Савић саставио, гласи: „На овом месту сахрањен је по изричитој жељи да буде у својој Топлици, код својих Топличана, које је тако волео и чијем је добру свој живот посветио.“
	
  У тестаменту написаном 16. јануара 1928. године  последња воља др Алексе Савића била је да велики део своје имовине остави Прокупљу и Прокупчанима. На брду Хисар надомак Прокупља, где је сахрањен, и данас постоји „Докторова кућа“, коју ја завештао народу Топлице да се ту изгради дом са видиковцем „Болеснима за опоравак, здравима за уживање“. Сада се зове Савићевац и представља један од заштитних најпрепознатљивијих знакова Града Прокупља. Тестаментом је оставио 400.000 динара за њену изградњу. То је била огромна сума за оно време. 
	
  Задужбину др Алексе Савића на Хисару пројектовао је чувени архитекта Драгутин Маслаћ, оснивач Удружења српских инжењера и архитеката и Kлуба архитеката, а ентеријер радио архитекта Руског царског двора чувени Николај Kраснов.
	
  Пут око брда Хисар Прокупчани су назвали "Пут др Алексе Савића".
	
  Мисију лекара у рату и миру пратила је једна енергична личност, великих организационих способности, разноврсних интересовања и визионарских подухвата. Залагањем Алексе Савића изграђена је модерна болница у Прокупљу. Подигнута је зграда резервоара за водовод Прокупља на Боровњаку, који је пошумљен захваљујући др Савићу. Отворена је Пољопривредна школа у Прокупљу, која још успешно ради и бележи вишедеценијску традицију постојања. Савићевим заузимањем подигнуте су бројне школе, изграђена парна купатила у Блацу и Kуршумлији, као и шездесетак хигијенских водних објеката. 
	
  У циљу увођења хигијенских пијаћих вода настадоше бројни бунари, пумпе и чесме у селима: Великој Плани, Белољину, Житном Потоку, Драгуши, Доњој Kоњуши, Вичи, Сварчи, Товрљану, Барбатовцу, Влахову, Житорађи, Добром Долу, Рачи, Дубрави, Микуљани, Данковићу, Точану, Лукову, Бећоглави, Вршевцу, Грабовници, Барлову, Пепељевцу, Kрчмару итд.
	
  И не само то, указом Др Алексе Савића изврши се пошумљавање пустих и огољених голети Топличког краја. Само на једном поседу засађено је 50.000 борова, 200 липа, 1000 јасена, док је брдо Хисар пошумљено са око 40.000 четинара, донешених са Kарпата. Ови четинари деценијама красе Прокупље и стару лепотицу Топлицу, реку која тече узбрдо једним делом свог тока, па се тако убраја у редак природни феномен. 
	
  Због свега урађеног у знак захвалности Др Алекси Савићу, Општа болница и Медицинска школа у Прокупљу данас носе име по њему. 
	
  Др Алекса Савић је једна од најзначајнијих личности за развој здравства у Топлици, а колико је био значајна личност за Прокупље и целу Топлицу, говоре добра дела која је оставио за собом.
	
  А због свега што је постигао и урадио у Прокупљу и Топличком крају морао је наћи места у овом историјском осврту на развоју здравства и медицине у коме је дао изузетан допринос и да је остао дуже у Прокупљу сигурно је да би Подружница Српског лекарског друштва у Прокупљу била основана много раније.
  
  Српско лекарско друштво Прокупље 2020. године, уз сагласност чланова Подружнице, покренуло је иницијативу да се уради репринт издања књиге "Лекар у служби свог народа - Др Алекса Савић" коју је написао Новица Шаулић и која је изашла из штампе 1936. године у издању Привредног прегледа из Београда.
  
  Новим штампаним издањем поменуте књиге желимо да упознамо све грађане Прокупља, Топличког краја и Србије са човеком који нас је много задужио у свим аспектима живота. Све о књизи је најбоље речено у уводној речи аутора Новицe Шаулића, савременика Др Алексе Савића  али морамо напоменути и нагласити и политичког опонента:
  
  "Пред вама је пример - народног лекара, ратника, управника и шефа, на делу, у схватању савременика, у успомени најдражих, од сељака којима је у рату и у миру био пријатељ и ради којих је створио хигијенску културу у своме крају.
  
  Пред вама је политичар, прави народни представник и министар слуга народних потреба.

  Пред вама је лични живот, рушење организма годинама и потпуно предан рад за народ, последња воља у складу са дота дањим животом: тестаменат којим је највећи део своје имовине оставио народу.
  
  Зато је ова књига живота потребна појединцу, да му покаже рад у служби својој земљи и народу, потребна друштву, да би осетило значај људи који су снага земље и који смрћу остављају место без замене. 

  Помисао на велико дело ових људи, изградњу заједничкога добра, радости душу а векови му певају песму.

  Жртва целога себе је најузвишенија жртва слободи, истини и свемирској љубави. Јер никад нисмо вечности ближе него кад живимо срцем за друге. Најлепша је и највиша срећа бити у служби овоме идеалу. Оваква служба, истински испуњена радом и несебичном жељом за опште добро, можда је најбоље решење тајне краткотрајнога живота.
  
  Ово је разумео и испунио Др Алекса Савић."`,

  istorijat2: `Наши предходници у Српском лекарском друштву Прокупље су били и покретачи иницијативе за рестаурирањем најстаријег споменика у Прокупљу подигнутог Др Алекси Савићу на Хисару 1932. године на месту на којем је по његовој вољи сахрањен, а који је био оштећен у рату.
  
  Изводи из извештаја о раду СЛД Прокупље за поменути период:

  "1964. године Подружница Српског лекарског друштва Прокупље је за грађане Прокупља и чланове Подружнице, 27. јануара 1964. године одржала комеморативно вече са предавањем о животу и раду др Алексе Савића, лекара који је на територији Топлице радио од 1904. — 1928. године и чијом је заслугом подигнута садашња болница и пољопривредна школа.

  Предавање је одржао С. Несторовић, директор "Народних новина" из Ниша. Имајући у виду живот и рад др Алексе Савића, као човека и лекара који је много допринео организовању и унапређењу здравствене службе на територији Топлице, Управни одбор уз сагласност чланова Подружице, одлучио је да предузме мере за реконструкцију надгробног спомени ка који се налази на Хисару."

  1966. године у оквиру свог друштвено-политичког рада Подружница је, уз помоћ Скупштине општине Прокупље, Медицинског центра у Прокупљу, Електричног предузећа у Прокупљу, Комуналне управе из Прокупља и Комбината обојених метала из Прокупља, рестаурисала надгробни споменик пок. др Алексе Савића, на Хисару.

  Откривању споменика присуствовали су представници друштвено-политичких организација и предузећа из Прокупља, председник Српског лекарског друштва проф. др Србољуб Стојиљковић, проф. др Војислав Стојановић, који је више година био хирург у Прокупљу, проф. др Јулка Поповић-Савић, синовица пок, др Алексе Савићa, председник Скупштине среза Ниш, секретар Среског комитета среза Ниш, председник општине Прокупље и други."

  Подружница Српског лекарског друштва у Прокупљу 2021. године покренула је иницијативу за поновним рестаурирањем споменика др Алекси Савићу, коју је у међувремену начео зуб времена, на платоу према његовој задужбини на брду Хисар, која је у лошем стању и иницијативу за подизање споменика у природној величини др Алекси Савићу који би био постављен испред новог Здравственог центра, који ће бити један од најсавременијих здравствених установа на Балкану.

`};
  },
};
</script>
<style scoped>
.resp-container {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
}
.resp-container {
  position: relative;
  overflow: hidden;
  width: 70%;
  height: 400px;
}
.resp-container-mobile {
  position: relative;
  overflow: hidden;
  height: 200px;
}
.resp-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.about {
  white-space: pre-line;
  line-height: normal;
  text-align: justify;
}
.link1 a {
    border-bottom: .05em solid #b4e7f8;
    box-shadow: inset 0 -0.05em 0 #b4e7f8;
    color: inherit;
    transition: background-color .25s cubic-bezier(.33, .66, .66, 1);
		text-decoration: none;
}

.link1 a:hover, .link1 a:focus, .link1 a:active {
    background-color: #b4e7f8;
}
.books {
  display: flex;
}
.books-mobile {
  display: block;
}
</style>