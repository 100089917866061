<template>
  <div>
    <div>
      <h1 class="mb-5">Часопис</h1>
      <hr />
      <div
        v-if="showLinks"
        v-bind:class="
          $vuetify.breakpoint.mobile
            ? 'magazine-wrapper-mobile'
            : 'magazine-wrapper'
        "
      >
        <div>
          <v-list dense>
            <v-subheader>Бројеви издања</v-subheader>
            <v-list-item-group v-model="selectedLink" color="primary">
              <v-list-item v-for="(link, i) in links" :key="i">
                <v-list-item-icon>
                  <v-icon></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <a  color="primary" :href="`https://drive.google.com/file/d/${link.listLinks}/view?usp=sharing`" target="_blank"
                      ><v-icon color="primary" large left>mdi-bookmark-plus-outline mdi-rotate-90 </v-icon>{{link.datum}}
                    </a>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
        <div class="d-flex justify-center">
          <iframe
            :src="this.links[this.links.length - 1].linkD"
            width="640"
            height="480"
            allow="autoplay"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "@/main";
export default {
  data: () => ({
    links: [],
    showLinks: false,
    selectedLink: {},
  }),
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      let snapshot = await db.collection("magazineLinks").get();
      let arr = [];
      snapshot.forEach((doc) => {
        let appData = doc.data();
        appData.id = doc.id;
        arr.push(appData);
        this.selectedLink = appData;
      });
      const sortedArticles = arr.sort((a, b) => b.date - a.date);
      this.links = sortedArticles;

      if (this.links.length > 0) {
        this.showLinks = true;
      } else {
        this.showLink = false;
      }
    },
  }
};
</script>

<style>
.magazine-wrapper {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 1rem;
}
.magazine-wrapper-mobile {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}
.ul {
  list-style: none;
}
</style>