<template>
  <div class="home">
    <h1 class="welcome-text">
      Добро дошли на сајт регионалне подружнице Српског лекарског друштва
      Прокупље
    </h1>
    <hr />
    <div
      class=""
      :class="$vuetify.breakpoint.mobile ? 'mobile-wrapper' : ''"
    >
    <v-container d-flex>
      <v-row>
        <v-col :class="$vuetify.breakpoint.mobile ? 'notices-mobile' : ''">
          <Notices/>
        </v-col>
        <v-col :class="$vuetify.breakpoint.mobile ? 'content-mobile' : ''">
          <div class="content1">
            <v-card class="mx-auto mb-5" >
          <!-- <v-img :aspect-ratio="16/9" src="../assets/nklinicki/1.jpg" height="200px"></v-img> -->
<!-- v-bind:style= "[condition_1 ? condition_2 ? {styleA} : {styleB} : {styleC}]" -->

          <v-card-title>
            ИЗБОР ПРЕДСЕДНИКА И ПРЕДСЕДНИШТВА
          </v-card-title>

          <v-card-subtitle> 29.јун 2023. </v-card-subtitle>
          <iframe src="https://drive.google.com/file/d/1U410oel2c7rjdbIHhOmdjzRBb2pq5U4c/preview" width="100%" height="480" allow="autoplay"></iframe>

        </v-card>
        <v-card class="mx-auto mb-5" >
          <v-img :aspect-ratio="16/9" src="../assets/nklinicki/1.jpg" height="200px"></v-img>
<!-- v-bind:style= "[condition_1 ? condition_2 ? {styleA} : {styleB} : {styleC}]" -->

          <v-card-title>
            Историјат подружнице Српског Лекарског Друштва
          </v-card-title>

          <v-card-subtitle> 1947. - 2020. </v-card-subtitle>

          <v-card-actions>
            <v-btn color="primary" dark @click.stop="dialog = true">
              Pročitaj više
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card class="mx-auto rukovodstvo-card mb-5" >
          <v-img
            src="../assets/185893.jpg"
            height="200px"
            :aspect-ratio="16/9"
          ></v-img>

          <v-card-title> Руководство Подружнице СЛД у Прокуљу </v-card-title>

          <v-card-subtitle> 1947. - 2020. </v-card-subtitle>

          <v-card-actions>
            <v-btn color="primary" dark @click.stop="dialog2 = true">
              Pročitaj više
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
        </v-col>
        <v-col :class="$vuetify.breakpoint.mobile ? 'news-mobile' : ''">
          <Vesti />
        </v-col>
      </v-row>
    </v-container>
    


      <v-dialog
        width="50%"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        hide-overlay
        transition="dialog-bottom-transition"
        scrollable
        v-model="dialog"
      >
        <v-card>
          <v-toolbar dark color="primary" fixed>
                  <v-toolbar-title
                    :class="$vuetify.breakpoint.mobile ? 'mobile-title' : ''"
                    class="white--text"
                    >Историјат подружнице Српког Лекарског Друштва</v-toolbar-title
                  >
                  <v-spacer></v-spacer>
                  <v-toolbar-items>
              <v-btn icon dark @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            </v-toolbar-items>
                </v-toolbar>
          <v-divider></v-divider>
          <v-card-text>
            <!-- <div>
              <v-app id="inspire">
                <v-toolbar dark color="primary" fixed>
                  <v-toolbar-title
                    :class="$vuetify.breakpoint.mobile ? 'mobile-title' : ''"
                    class="white--text"
                    >ИСТОРИЈАТ ПОДРУЖНИЦЕ СРПСКОГ ЛЕКАРСКОГ ДРУШТВА</v-toolbar-title
                  >
                  <v-spacer></v-spacer>
                </v-toolbar>
                
              </v-app>
            </div> -->
            <p class="about">{{ istorijat }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="blue darken-1" text @click="dialog = false">
              Затвори
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        width="50%"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        hide-overlay
        transition="dialog-bottom-transition"
        scrollable
        v-model="dialog2"
      >
        <v-card>
          
                <v-toolbar dark color="primary" fixed>
                  <v-toolbar-title
                    :class="$vuetify.breakpoint.mobile ? 'mobile-title' : ''"
                    class="white--text"
                    >Руководство Подружнице СЛД у Прокуљу</v-toolbar-title
                  >
                  <v-spacer></v-spacer>
                  <v-toolbar-items>
              <v-btn icon dark @click="dialog2 = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                <v-layout v-resize="onResize" column style="padding-top: 56px">
                  <v-data-table
                    :headers="headers"
                    :items="tableDat"
                    :hide-default-header="isMobile"
                    :class="{ mobile: isMobile }"
                  >
                    <template slot="items" slot-scope="props">
                      <tr v-if="!isMobile">
                        <td>{{ props.item.name }}</td>
                        <td class="text-xs-right">{{ props.item.calories }}</td>
                        <td class="text-xs-right">{{ props.item.Година }}</td>
                        <td class="text-xs-right">
                          {{ props.item.Председник }}
                        </td>
                        <td class="text-xs-right">{{ props.item.Секретар }}</td>
                        <td class="text-xs-right">{{ props.item.Секретар }}</td>
                        <!-- <td class="text-xs-right">{{ props.item.iron }}</td> -->
                      </tr>
                      <tr v-else>
                        <td>
                          <ul class="flex-content">
                            <li class="flex-item" data-label="Година">
                              {{ props.item.Година }}
                            </li>
                            <li class="flex-item" data-label="Година">
                              {{ props.item.Година }}
                            </li>
                            <li class="flex-item" data-label="Председник">
                              {{ props.item.Председник }}
                            </li>
                            <li class="flex-item" data-label="Секретар">
                              {{ props.item.Секретар }}
                            </li>
                            <li
                              class="flex-item"
                              data-label="Предсетништво__I__Upravni_Odbor"
                            >
                              {{ props.item.Предсетништво__I__Upravni_Odbor }}
                            </li>
                            <!-- <li class="flex-item" data-label="Protein (g)">{{ props.item.protein }}</li>
                      <li class="flex-item" data-label="Iron (%)">{{ props.item.iron }}</li> -->
                          </ul>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-layout>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="blue darken-1" text @click="dialog2 = false">
              Затвори
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>

export default {
  name: "Home",
  components: {
    Vesti: () => import("./Vesti.vue"),
    Notices: () => import("../components/Notices"),
  },
  methods: {
    onResize() {
      if (window.innerWidth < 769) this.isMobile = true;
      else this.isMobile = false;
    },
  },
  data() {
    return {
      dialog: false,
      dialog2: false,
      dialogm1: "",
      mobileBreakpoint: 600,
      isMobile: false,
      headers: [
        {
          text: "Година",
          align: "left",
          value: "Година",
        },
        {
          text: "Председник",
          value: "Председник",
        },
        {
          text: "Секретар",
          value: "Секретар",
        },
        {
          text: "Предсетништво и управни одбор",
          value: "Предсетништво__I__Upravni_Odbor",
        },
        {
          text: "Број чланова",
          value: "Број_чланова",
        },
      ],
      tableDat: [
        // { Година: '', Председник: '', Секретар: '', Предсетништво__I__Upravni_Odbor: '', Број_чланова: '' },
        { Година: "08.06.1947", Председник: "ОСНОВАНА ПОДРУЖНИЦА" },
        {
          Година: 1955,
          Председник: "Др Д. Николић",
          Секретар: "",
          Предсетништво__I__Upravni_Odbor: "Др М. Максимовић, Др Т. Стевановић",
          Број_чланова: "10 od 21",
        },
        {
          Година: 1959,
          Председник: "",
          Секретар: "",
          Предсетништво__I__Upravni_Odbor: "",
          Број_чланова: "21 od 33",
        },
        {
          Година: 1961,
          Председник: "Др М. Милановић",
          Секретар: "Др П. Џогановић",
          Предсетништво__I__Upravni_Odbor: "",
          Број_чланова: "",
        },
        {
          Година: 1962,
          Председник: "Др Васа Дражић",
          Секретар: "Др Д. Дробњак",
          Предсетништво__I__Upravni_Odbor: "",
          Број_чланова: "30",
        },
        {
          Година: 1964,
          Председник: "Др Васа Дражић",
          Секретар: "Др М. Стошковић",
          Предсетништво__I__Upravni_Odbor:
            "Др Ј. Баловић, Др Д. Дробњак, Др С. Васов, Др М. Весковић",
          Број_чланова: "",
        },
        {
          Година: 1965,
          Председник: "Др Васа Дражић",
          Секретар: "Др М. Стошковић",
          Предсетништво__I__Upravni_Odbor:
            "Др М. Павловић, Др Д. Дробњак, Др Ј. Баловић, Др Д. Мирковић, Др В. Рајковић",
          Број_чланова: "52",
        },
        {
          Година: 1966,
          Председник: "Др Васа Дражић",
          Секретар: "",
          Предсетништво__I__Upravni_Odbor: "",
          Број_чланова: "59",
        },
        {
          Година: 1972,
          Председник: "Др Васа Дражић",
          Секретар: "Др Богосав Ђорђевић",
          Предсетништво__I__Upravni_Odbor: "Др Р. Шагрић",
          Број_чланова: "94",
        },
        {
          Година: 1981,
          Председник: "Др М. Ћирић",
          Секретар: "Др Р. Милићевић",
          Предсетништво__I__Upravni_Odbor: "Др Тодор Цветановић",
          Број_чланова: "112",
        },
        {
          Година: 1982,
          Председник: "Др Тодор Цветановић",
          Секретар: "Др С. Денић",
          Предсетништво__I__Upravni_Odbor: "",
          Број_чланова: "104",
        },
        {
          Година: 1987,
          Председник: "Др С. Вукићевић",
          Секретар: "Др Радмила Трифуновић",
          Предсетништво__I__Upravni_Odbor: "Др М. Ратковић",
          Број_чланова: "",
        },
        {
          Година: 1991,
          Председник: "Др Драган Јовановић",
          Секретар: "Др П. Џогановић",
          Предсетништво__I__Upravni_Odbor: "Др Душанка Миленковић",
          Број_чланова: "",
        },
        {
          Година: 1994,
          Председник: "Др Душанка Миленковић",
          Секретар: "Др Јелена Баловић",
          Предсетништво__I__Upravni_Odbor: "",
          Број_чланова: "",
        },
        {
          Година: 1996,
          Председник: "Др Радиша Перовић",
          Секретар: "Др Љиљана Обрадовић",
          Предсетништво__I__Upravni_Odbor: "",
          Број_чланова: "",
        },
        {
          Година: 2000,
          Председник: "Др Љиљана Обрадовић",
          Секретар: "Др Снежана Јовановић",
          Предсетништво__I__Upravni_Odbor: "Др Љубисав Ђаловић, Др Бојан Данић",
          Број_чланова: "",
        },
        {
          Година: 2005,
          Председник: "Др Радмила Живковић",
          Секретар: "Др Радмила Трифуновић",
          Предсетништво__I__Upravni_Odbor: "",
          Број_чланова: "",
        },
        {
          Година: 2009,
          Председник: "Др Дарко Лакетић",
          Секретар: "Др Јагода Перовић",
          Предсетништво__I__Upravni_Odbor:
            "Др Србољуб Стошић, Др Небојша Вукадиновић, Др Драгица Марковић",
          Број_чланова: "",
        },
        {
          Година: 2014,
          Председник: "Др Јагода Перовић",
          Секретар: "Др Братислав Васиљевић",
          Предсетништво__I__Upravni_Odbor: "",
          Број_чланова: "188",
        },
        {
          Година: 2019,
          Председник: "Др Срђан Мијатовић",
          Секретар:
            "потпредседник Др Братислав Васиљевић, секретар Др Јулија Јовановић",
          Предсетништво__I__Upravni_Odbor:
            "Др Србољуб Стошић, Др Небојша Вукадиновић, Др Драгица Марковић",
          Број_чланова: "216",
        },
      ],
      istorijat: `Подружница Српског лекарског друштва у Прокупљу основана је 08. јуна 1947. године, како се и наводи у монографији "Српско лекарско друштво 1872-2017", која је издата поводом 145 година од оснивања Српског лекарског друштва.

                  Подружница Српског лекарског друштва Прокупље окупља лекаре који су запослени у здравственим установама на територији целог Топличког округа односно у Граду Прокупљу и општинама Житорађи, Блацу и Куршумлији. До 1992. године и лекари Дома здравља из Мерошине били део наше Подружнице. Поменуте године општина Мерошина излази из Топличког округа и припаја се Граду Нишу.

                  Кроз преко 7 деценија свог трајања Српског лекарског друштва у Топличком крају је пролазило кроз многе тешкоће и искушења остајући верно својим изворним начелима и задацима и то неговању и унапређењу медицинске струке и науке ради заштите и унапређења народног здравља уз сарадњу са свим друштвеним актерима укљученим у остваривање обједињене здравствене политике.

                  Одувек је Подружница Српског лекарског друштва Прокупље била подржана великим ентузијазмом лекара, њених чланова и добром вољом руководства здравствених установа, локалне политичке заједнице, донаторима из привреде и фармацеутских сарадника. Сва средства којим располаже Српско лекарско друштво Прокупље су из скромне чланарине али су планирани циљеви увек остваривани на леп и достојанствен начин.

                  Српско лекарско друштво Прокупље данас баштини велику традицију својих предходника. Наследили смо Јунске сусрете лекаре Топлице и Лекарску славу Свети Врачи као манифестације са веома дугим периодом трајања.

                  Традиционални Јунски сусрету лекара Топлице у организацији Српског лекарског друштва Прокупље су 2020. године одржани по 58. пут у континуитету и тиме представљају здравствене састанке са најдужом традицијом континуираног одржавања у Србији.

                  Посебно смо поносни на чињеницу да је Подружница Српског лекарског друштва у Прокупљу била међу првим подружницама у Србији која је почетком 90-их година прошлог века обновила обележавање крсне славе.	

                  Српско лекарско друштво Прокупље је 1994. године организовало прославу обележавања лекарске славе Светих Врача. Др Душанка Миленковић је била први колачар и домаћица славе. У међувремену, Свети Врачи су постали и више од славе, постали су тачка наше саборности и осећај припадности еснафу. 

                  Након Другог светског рата, у току 1946. и 1947. године формирано је преко 30 подружница широм Србије а међу њима и Подружница у Прокупљу.

                  Руководство Српског лекарског друштва је успело да се после Другог светског рата наметне и да добије поверење од државног руководства да организује здравствени систем у Србији. У постизање тог циља Подружнице су предвиђене да буду носиоци тог посла и већ 1946. године донет је правилник о њиховом раду. 

                  Задаци Подружница били су рад на стручном подизању чланства, истраживачки рад на терену, помоћ народним властима у бољој организацији здравствене службе, рад на здравственом просвећивању широких народних маса и рад на популарисању медицинске науке.

                  Основни задаци који су стављени пред Подружницу Српског лекарског друштва у Прокупљу су очигледно успешно и реализовани. Нажалост из тог периода немамо много писане документације па не можемо детаљније представити начин рада и функционисања Подружнице Српског лекарског друштва у Прокупљу на самом њеном почетку.

                  На основу доступних писаних годишњих извештаја који су достављани  централи из Подружнице у Прокупљу представићемо хронолошки рад Подружнице од оснивања.

                  Први писани трагови са детаљнијим описом састанака Подружнице Српског лекарског друштва Прокупље су од 17.12.1955. године. Тада је за председника Српског лекарског друштва у Прокупљу изабран Др Д. Николић.

                  Те године се врло активно ради на организацији и сарадњи са Подружницама у Нишу и Београду, епидемиолошкој ситуацији у срезу (ТБЦ, значај БСГ вакцинације и др) као и унапређењу терапије у гинекологији (хормонска терапија).

                  1956. године се ради на учлањењу лекара у Српског лекарског друштва, стручном уздизању лекара, сарадњи са друштвеним организацијама, народним властима и здравственом просвећивању народа у срезу Прокупље. Учлањено је 10 лекара од укупно 21 лекара, због презаузетости лекара на терену.

                  У новембру 1956. године одржан је састанак по типу консултација на припремљеном болничком материјалу са гостима из Београда, Проф. Др В.Стојановићем, Проф. Др Ј. Славковићем, Доц. др Д. Младеновићем и др А. Шевићем уз питања и општу дискусију.

                  1959. године подружница је имала 21. члана од укупно 33 лекара. Наводи се да су неучлањени лекари били чланови других подружница јер су повремено долазили на стаж.

                  17.04.1959. године одржан је састанак Српског лекарског друштва Прокупље са темом избор Суда части и дискусија о укидању приватне лекарске делатности. Поменута тема је подразумевала Прораду Закона о регулисању приватне лекарске делатности у заједници са представницима власти и Завода за социјално осигурање. Те године одржано је 11 стручних састанака из разних медицинских области по актуелним темама. Одржана су и предавања у Прокупљу, Kуршумлији и Блацу о хигијени насеља, значају БСГ вакцинације. Изведене су добровољне акције бесплатних прегледа у селима Балајинцу и Жучу.

                  У току наредних година до 1966. године одвијао се активни здравствено просветни рад са приказивањем филмова, у виду курсева за здравствено просвећивање сеоске омладине, деце, курсева прве помоћи у виду разговора о здрављу. теме су биле туберкулоза, Вариола Вера - др Тодора Цветановић. Те године извршена је акција 800 стоматолошких прегледа и 200 екстракција зуба.

                  У годишњем извештају за ту годину стоји да су све ове акције биле подржане предусретљивом сарадњом друштвено политичке заједнице, Црвеног крста и свих здравствених радника Медицинског Центра из Прокупља.

                  1961. године Српско лекарско друштво Прокупље по први пут организује састанак лекара Југоисточне Србије на Малом Јастрепцу. Та локација је остала традиционална у организацији Сусрета лекара неких 20-ак година а затим се организација пребацује у Прокупље. Сами Сусрети лекара су мењали места одржавања, садржај, програм и трајање али су руководства Српског лекарског друштва Прокупље успели да одрже континуитет и традицију. 

                  Једина година када Јунски сусрети лекара Топлице нису одржани је 1999. године због ратног стања и злочиначког бомбардовања наше земље. Тада је већина наших лекара била ангажована у одбрани земље и народа. 

                  Назив ових традиционалних сусрета се временом усталио као Јунски сусрети лекара Топлице који су у свом трајању угостили велики број најеминентнијих стручњака из земље и иностранства.

                  20. јуна 1965. године одржани су Сусрети лекара са предавачима из Београда Проф. др В. Стојановић и др Ј. Мићић коме су присуствовали лекари из Ниша, Лесковца, Врања и Алексинца.

                  Поред бројних активности 1966. године Подружница Српског лекарског друштва у Прокупљу у сарадњи са широм друштвеном политичком заједницом у Прокупљу извршила је рестаурацију надгробног споменика др Алекса Савића, Откривању је присуствовао председник Српског лекарско друштва Проф. Др Србољуб Стојиљковић и Проф. др Јулка Поповић-Савић братаница Др Алексе Савића као и сви представницих свих значајних установа и институција из Прокупља и Ниша.

                  Октобра 1967. године Скупштина општине Прокупље доделила је Подружници Српског лекарско друштва Прокупље Награду ослобођења града Прокупља. У октобру 1968. године награду Ослобођења града Прокупља добио је тадашњи председник Подружнице хирург др Васа Дражић

                  У том период шездесетих и седамдесетих година запажа се добра сарадња са здравственим принципима Руске медицине: др О.Симић Организација и рад у Институту за гинекологију и акушерство у Лењинграду и организовано студијско путовање болницама у Бугарској од 25-29.09.1969. године.

                  1970. године одржан је стручни састанак 150 хирурга Србије са гостима из Хрватске. Састанку су допринела значајна имена као Проф. Др В. Стојановић, Проф. Др Бора Вујадиновић и др.

                  1971. године састанак стоматолога Југоисточне Србије одржан je у Kуршумлиjској Бањи.

                  1970. године први пут су подељене захвалнице за рад у Српског лекарског друштва. За јунски састанак 1974. године Подружница је добила филмове о актуелним темама из здравства од Амбасаде Француске.

                  1997. године тадашњи председник Др Радиша Перовић и сарадици из Председништва Подружнице Српског лекарског друштва Прокупље купују први компјутер са штампачем у Здравственом центру Прокупље.

                  1998. године званично је покренута иницијатива за издавање научног часописа чији би издавач била Подружница Српског лекарског друштва у Прокупљу.

                  2000. године тадашња председница Др Љиљана Обрадовић је организовала промоцију књиге  "Хирургија" Проф. др Милана Вишњића и књиге "Дневник ратног хирурга" Прим. др Миодрага Лазића. Промоцију је својим предавањем о ратним повредима увеличао и Проф. др Славиша Добричанин.
                    
                  Задаци Подружнице од самог оснивања далеке 1947. године  били су на стручном подизању чланства, истраживачки рад на терену, помоћ око што боље изградње здравственог система и рад на популарисању медицине и од њих се никад до данас није одустало.

                  Основни циљ на Јунским сусретима лекара Топлице, и свим осталим стручним састанцима у организацији Српског лекарског друштва Прокупље, је да предавачи али и остали учесници изнесу сопствене ставове као и да кроз конструктиван дијалог понуде одређена савремена и могућа решења и дају одређене научне и стручне препоруке за даљи развој наведене области у нашој средини.
                  
                  Увидом у предавања која су презентована годинама уназад евидентни су актуелност тема и стручност предавача. У оквиру реализације наведених научних скупова, активно учешће су узимали поред лекара из Топлице и чланова СЛД Прокупље и универзитетски професори, еминентни стручњаци из земље и иностранства. Kада се погледа списак предавача са стране, али и наших колега из Топлице, и теме које су биле на дневном реду састанака, то је импозантна листа професора, колега експерата у свом домену и пренетих искустава на лекаре у Топлици.
                  
                  Некада није било конгреса (или не у овом броју, нити могућности да се оде на едукацију) па су стручни састанци Српског лекарског друштва Прокупље били својеврсни центри за едукацију лекара из Топличког краја а и шире околине. 
                  
                  Дискусије колега после презентација радова или предавања биле су и остале веома интересантне и корисне за све присутне. Ту се укрштају стручна мишљења искусних и старијих колега које млади лекари упијају и много из њих могу да науче. Kроз те састанке смо сви научили да се поштује струка али и године искуства које имају старије колеге. Енергија младих и искуство старијих колега, рецепт је који годинама опстаје и траје и којим се у Српском лекарском друштву Прокупље поносимo
                  
                  Еминентним стручњацима који су свој развојни и професионални пут започели у Топлици част и задовољство је било да презентују своје стручне и научне публикације а међу њима су били Проф. др Војин Шуловић, Проф. др Милан Вишњић, Проф. др Славиша Добричанин, Проф. др Владмила Бојанић, Проф. др Томислав Јовановић, Проф. др Радоје Илић...
                  
                  На Јунским сусретима лекара и осталим стручним састанцима, поштовале су се актуелне теме и чланство је увек било адекватно обавештавано. Након уведене обавезе лекара за прикупљања бодова потребних ради обнављања лиценце, чланство Српског лекарског друштва Прокупље је скоро увек било и испоштовано комплетним бодовима неопходним за обнављање лиценце. 
                  
                  И сада Подружница у Прокупљу прати актуелна збивања и служи за учење и пример одржавши годишње Сусрете лекара традиционално 2020. године 58. пут по реду са применом свих противепидемијских мера у доба пандемије Короне у сали Дома Kултуре дајући значајан допринос у прикупљању бодова за лиценцу својим члановима. 
                  
                  Идеја о штампању часописа датира из 1998. године али до реализације није дошло. Веома смо поносни да је Подружница ипак успела 2020. године да окупи тим и оформи научни часопис. Научни часопис Српског лекарског друштва Прокупље издаваће се под именом "ACTA MEDICA HAMMEUMI".  Ушло се у завршне припреме издавања научног часописа и први штампани примерак очекујемо почетком јуна 2021. године.
                  
                  Свака епоха у једном народу има своје доброчинитеље и патриоте, велике лекаре и хуманисте, драгоцене људе. Све ово заједно носио је у свом лику др Алекса Савић (1878.-1928.), хуманиста и човек топлог срца, који је задужио српску медицину али и оплеменио Топлицу коју је волео и којој је остао занавек веран. Српско лекарско друштва Прокупље покренуло је иницијативу да се уради репринт издања књиге "Лекар у служби свог народа - Др Алекса Савић" коју је написао Новица Шаулић и која је изашла из штампе 1936. године. Новим штампаним издањем поменуте књиге желимо да упознамо све грађане Прокупља и Топличког краја са др Алексом Савићем који је много задужио у свим аспектима живота, како Прокупље тако и цео Топлички крај. 
                  
                  Обновили смо 2020. године доделу награда заслужним члановима Српског лекарског друштва Прокупље. Награде заслужним члановима Српског лекарског друштва Прокупље су додељиване од 70-их година прошлог века али се та пракса у међувремену изгубила. Ове године смо формирали Комисију за доделу награда и признања у склопу Српског лекарског друштва Прокупље. Има доста заслужних чланова који су својим савесним лекарским радом и поштовањем етичких принципа стекли углед у стручној и општој јавности а својим таквим понашањем допринели и очувању угледа Српског лекарског друштва у Прокупљу.
                  
                  У складу са савременим токовима и ради бољег и лакшег информисања чланства покренут је сајт Српског лекарског друштва Прокупље. На сајту СЛД Прокупље поред актуелних дешавања и тема, корисних линкова су и информације о историјату СЛД у Прокупљу, историјату здравства у Топлици и све о лику и делу др Алексе Савића. Сајт Српског лекарског друштва даће додатне могућности члановима Српског лекарског друштва Прокупље а савременим изгледом и лакшу доступност акредитованим стручним скуповима и тестовима за континуирану медицинску едукацију коју омогућавамо својим члановима сваке године.
                  
                  Оно што морамо са поносом истаћи је да је договорено са руководством Опште болнице и Дома Здравља у Прокупљу, а уз велику подршку нашег бившег председника Српског лекарског друштва Прокупље Доц. др Дарка Лакетића да у склопу новог савременог Здравственог центра у Прокупљу Српског лекарског друштва Прокупље добије своју канцеларију и простор за библиотеку коју ћемо опремити најбољом стручном литературом која ће бити доступна свим нашим члановима. 
                  
                  У сусрет 2022. години и обележавању 75 година постојања и рада Подружнице Српског лекарског друштва  у Прокупљу, Председништво је донело одлуку о издавању Монографије "75 година рада Подружнице Српског лекарског друштва у Прокупљу 1947-2022". 
                  
                  Сви чланови Српског лекарског друштва Прокупље су део поносне традиције дуге преко 7 деценија коју су започеле и стварале наше старије колеге а ми смо ту да то одржимо и наставимо на истом нивоу.  
                  
                  Данас поштујемо традицију, доследни смо начелима Подружнице од њеног оснивања и у сагласју са новим трендовима усавршавамо рад Друштва и наш светионик здравства Топлице - Српско лекарско друштво Прокупље и даље сија још јачим сјајем. 
                  `,
    };
  },
};
</script>
<style>
.wrapper {
  display: grid;
  grid-auto-rows: minmax(min-content, max-content);
  grid-template-columns: 1fr auto 1fr;
  gap: 3rem;
  padding: 10px;
  text-align: center;
  text-align: justify;
}
.mobile-wrapper {
  display: grid;
  place-content: center;
  gap: unset;
  grid-template-columns: 1fr;
}
.content1 {
  order: 1;
}
.notice {
  order: 2;
}
.notices-mobile {
  order: 3;
}
.content-mobile {
  order: 1;
}
.news-mobile {
  order: 2;
}
.welcome-text {
  text-align: center;
  padding: 10px;
}
.about {
  white-space: pre-line;
  line-height: normal;
  text-align: justify;
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  width: 100%;
  border-top: 4px solid #4d81c0;
}
.table {
  border-top: 4px solid #4d81c0;
}
.mobile {
  color: #333;
}

@media screen and (max-width: 768px) {
  .mobile table.v-table tr {
    max-width: 100%;
    position: relative;
    display: block;
  }

  .mobile table.v-table tr:nth-child(odd) {
    border-left: 6px solid deeppink;
  }

  .mobile table.v-table tr:nth-child(even) {
    border-left: 6px solid cyan;
  }

  .mobile table.v-table tr td {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #f5f5f5;
    height: auto;
    padding: 10px;
  }

  .mobile table.v-table tr td ul li:before {
    content: attr(data-label);
    padding-right: 0.5em;
    text-align: left;
    display: block;
    color: #999;
  }
  .v-datatable__actions__select {
    width: 50%;
    margin: 0px;
    justify-content: flex-start;
  }
  .mobile .theme--light.v-table tbody tr:hover:not(.v-datatable__expand-row) {
    background: transparent;
  }
}
.flex-content {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.flex-item {
  padding: 5px;
  width: 50%;
  height: 40px;
  font-weight: bold;
}

.mobile-title {
  font-size: unset;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: wrap;
}
.v-dialog--scrollable,
.v-dialog--scrollable > form {
  display: flex;
  justify-content: center;
}
.vdialog {
  width: 50%!important;
}

.rukovodstvo-card {
  grid-area: midle;
}
</style>