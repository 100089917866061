<template>
  <div>
    <h1 class="mb-5">ГАЛЕРИЈА</h1>
    <hr />
    <div>
      <v-dialog
        v-model="dialog"
        width="50%"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <div class="gallery mb-5">
            <v-card
              class="mx-auto pa-6"
              elevation="2"
              hover
              v-on="on"
              @click="chooseFolder('nklinicki')"
            >
              <v-img
                height="200"
                v-bind="attrs"
                class="choose-folder"
                thumbnail
                fluid
                src="../assets/nklinicki/1.jpg"
                alt="sldprokuplje"
              ></v-img>
              <v-card-title class="d-flex justify-center"
                >Нови Здравствени Центар</v-card-title
              >
            </v-card>
            <v-card
              class="mx-auto pa-6"
              elevation="2"
              hover
              v-on="on"
              @click="chooseFolder('60')"
            >
              <v-img
                height="200"
                v-bind="attrs"
                class="choose-folder"
                thumbnail
                fluid
                src="../assets/60/1.jpg"
                alt="sldprokuplje"
              ></v-img>
              <v-card-title class="d-flex justify-center">60-те</v-card-title>
            </v-card>
            <v-card
              class="mx-auto pa-6"
              elevation="2"
              hover
              v-on="on"
              @click="chooseFolder('Junski_susreti_2020')"
            >
              <v-img
                height="200"
                v-bind="attrs"
                class="choose-folder"
                thumbnail
                fluid
                src="../assets/Junski_susreti_2020/1.jpg"
                alt="sldprokuplje"
              ></v-img>
              <v-card-title class="d-flex justify-center"
                >Јунски Сусрети 2020</v-card-title
              >
            </v-card>
            <v-card
              class="mx-auto pa-6"
              elevation="2"
              hover
              v-on="on"
              @click="chooseFolder('osvcenje_ikone_2020')"
            >
              <v-img
                height="200"
                v-bind="attrs"
                class="choose-folder"
                thumbnail
                fluid
                src="../assets/osvcenje_ikone_2020/1.jpg"
                alt="sldprokuplje"
              ></v-img>
              <v-card-title class="d-flex justify-center"
                >Освећење Иконе 2020</v-card-title
              >
            </v-card>
            <v-card
              class="mx-auto pa-6"
              elevation="2"
              hover
              v-on="on"
              @click="chooseFolder('sveti_vraci_2019')"
            >
              <v-img
                height="200"
                v-bind="attrs"
                class="choose-folder"
                thumbnail
                fluid
                src="../assets/sveti_vraci_2019/1.jpg"
                alt="sldprokuplje"
              ></v-img>
              <v-card-title class="d-flex justify-center"
                >Свети Врачи 2020</v-card-title
              >
            </v-card>
            <v-card
              class="mx-auto pa-6"
              elevation="2"
              hover
              v-on="on"
              @click="chooseFolder('90_2000')"
            >
              <v-img
                height="200"
                v-bind="attrs"
                class="choose-folder"
                thumbnail
                fluid
                src="../assets/90_2000/1.jpg"
                alt="sldprokuplje"
              ></v-img>
              <v-card-title class="d-flex justify-center"
                >СЛД Прокупље 90-е и 2000-е</v-card-title
              >
            </v-card>
          </div>
          <div
            class="mx-auto"
            :class="
              $vuetify.breakpoint.mobile
                ? 'resp-container-mobile'
                : 'resp-container'
            "
          >
            <iframe
              frameBorder="55"
              class="resp-iframe"
              src="https://player.vimeo.com/video/534127384"
              width="640"
              height="564"
              allow="autoplay; fullscreen"
              allowfullscreen
            ></iframe>
          </div>
        </template>

        <v-card style="height: 100vh">
            

          <v-carousel hide-delimiters height="100%" v-model="model" style="height: 100vh">
            <!-- <div class="primary d-flex">
              <v-btn color="white--text" icon dark @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
              <v-spacer></v-spacer>
              <h3 class="mr-5 mt-1 white--text">{{modalName}}</h3>
            </div> -->
            <v-toolbar dark color="primary" fixed>
                  <v-toolbar-title
                    :class="$vuetify.breakpoint.mobile ? 'mobile-title' : ''"
                    class="white--text"
                    >{{modalName}}</v-toolbar-title
                  >
                  <v-spacer></v-spacer>
                  <v-toolbar-items>
              <v-btn icon dark @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            </v-toolbar-items>
                </v-toolbar>
            
            <v-carousel-item
              style="height: 100v%"
              v-for="item in selectedImageFolder"
              :key="item"
            >
              <v-sheet :item="item" height="100%" tile>
                <v-img
                  contain
                  style="height: 100%"
                  alt="sldprokuplje"
                  :src="getImageUrl(item, folderName)"
                >
                </v-img>
                
              </v-sheet>
            </v-carousel-item>
          </v-carousel>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mainProps: {
        center: true,
        fluidGrow: false,
        blank: true,
        blankColor: "#bbb",
        width: 1024,
        height: 400,
        class: "my-5",
      },
      model: 0,
      isKlinicki: false,
      is60: false,
      isJunski: false,
      isIkone: false,
      isVraci: false,
      is90_2000: false,
      slide: 0,
      sliding: null,
      counter: 0,
      klinicki: [1, 4, 5, 6, 7, 8],
      sixtys: [1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,],
      Junski_susreti_2020: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
      ],
      osvcenje_ikone_2020: [1, 2, 3, 4, 5],
      sveti_vraci_2019: [1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14],
        nineties: [1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27
        ],
      selectedImageFolder: [],
      folderName: "",
      largeImage: "",
      modalName: "",
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
    };
  },
  methods: {
    getImageUrl(imageId, album) {
      this.counter = imageId;
      return require(`@/assets/${album}/${imageId}.jpg`);
    },
    chooseFolder(e) {
      switch (e) {
        case "nklinicki":
          this.folderName = "nklinicki";
          this.selectedImageFolder = this.klinicki;
          this.isKlinicki = true;
          this.modalName = "Нови Здравствени центар";
          this.is60 = false;
          this.isJunski = false;
          this.isIkone = false;
          this.isVraci = false;
          this.is90_2000 = false;
          break;

        case "60":
          this.folderName = "60";
          this.selectedImageFolder = this.sixtys;
          this.is60 = true;
          this.modalName = "Слике из 60-тих";
          this.isKlinicki = false;
          this.isJunski = false;
          this.isIkone = false;
          this.isVraci = false;
          this.is90_2000 = false;

          break;

        case "Junski_susreti_2020":
          this.folderName = "Junski_susreti_2020";
          this.selectedImageFolder = this.Junski_susreti_2020;
          this.isJunski = true;
          this.modalName = "Junski susreti 2020";
          this.isKlinicki = false;
          this.is60 = false;
          this.isIkone = false;
          this.isVraci = false;
          this.is90_2000 = false;

          break;

        case "osvcenje_ikone_2020":
          this.folderName = "osvcenje_ikone_2020";
          this.selectedImageFolder = this.osvcenje_ikone_2020;
          this.isIkone = true;
          this.modalName = "Osvcenje ikone 2020";
          this.isKlinicki = false;
          this.is60 = false;
          this.isJunski = false;
          this.isVraci = false;
          this.is90_2000 = false;

          break;

        case "sveti_vraci_2019":
          this.folderName = "sveti_vraci_2019";
          this.selectedImageFolder = this.sveti_vraci_2019;
          this.isVraci = true;
          this.isIkone = false;
          this.modalName = "Sveti Vraci 2019";
          this.isKlinicki = false;
          this.is60 = false;
          this.isJunski = false;
          this.is90_2000 = false;
          break;

          case "90_2000":
          this.folderName = "90_2000";
          this.selectedImageFolder = this.nineties;
          this.is90_2000 = true;
          this.isIkone = false;
          this.modalName = "СЛД Прокупље 90-е и 2000-е"
          this.isKlinicki = false;
          this.is60 = false;
          this.isJunski = false;
          break;

        default:
          break;
      }
    },
    onSlideStart() {
      this.counter++;
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
};
</script>
<style scoped>
.albums {
  margin-bottom: 200px;
}
.album {
  background-color: #848b8b;
}
.img-wrapper {
  column-count: 5;
  column-gap: 0px;
}
.grid-img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
  transition: opacity 0.25s ease-in-out;
}
.gallery {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(344px,1fr));
  place-content: center;
}
.v-window-item.v-image.v-responsive.v-carousel__item.theme--dark {
  height: 100% !important;
}
.resp-container {
  position: relative;
  overflow: hidden;
  width: 70%;
  height: 400px;
}
.resp-container-mobile {
  position: relative;
  overflow: hidden;
  height: 200px;
}
.resp-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
@media only screen and (min-width: 600px) {
  .v-carousel__controls {
    height: 100px!important;
}
}
</style>