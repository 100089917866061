<template>
  <!-- App.vue -->
  <v-app fill-height class="router-wrapper">
    <v-navigation-drawer
      class="app-drawer primary lighten-2"
      app
      src="https://cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg"
      style="height: 100%"
      v-model="drawer"
      fixed
    >
      <v-list class="primary" v-if="$vuetify.breakpoint.mobile" nav dense>
        <v-list-item-group
          v-model="group"
          active-class="#457EBA text--accent-4"
        >
          <v-list-item to="/">
            <v-list-item-title>Почетна</v-list-item-title>
          </v-list-item>

          <v-list-item color="white" large left to="/about">
            <v-list-item-title>О нама</v-list-item-title>
          </v-list-item>

          <v-list-item color="white" large left to="/organization">
            <v-list-item-title>Организација</v-list-item-title>
          </v-list-item>

          <v-list-item color="white" large left to="/gallery">
            <v-list-item-title>Галерија</v-list-item-title>
          </v-list-item>

          <v-list-item color="white" large left to="/membership">
            <v-list-item-title>Постани члан</v-list-item-title>
          </v-list-item>

          <v-list-item color="white" large left to="/contact">
            <v-list-item-title>Контакт</v-list-item-title>
          </v-list-item>

          <v-list-item color="white" large left to="/aleksaSavic">
            <v-list-item-title>Др Алекса Савић</v-list-item-title>
          </v-list-item>

          <v-list-item color="white" large left to="/amh">
            <v-list-item-title>Часопис</v-list-item-title>
          </v-list-item>

        </v-list-item-group>
      </v-list>

      <v-list
        :class="!$vuetify.breakpoint.mobile ? 'list-wrapper' : ''"
        nav
        dense
      >
        <v-list-item-group
          v-bind:class="{ linkWrapper: !$vuetify.breakpoint.mobile }"
          v-model="group"
        >
          <v-list-item href="http://www.sld.org.rs/" target="_blank">
            <v-list-item-title>
              <a href="http://www.sld.org.rs/" class="link"
                ><v-icon color="white" large left>
                  mdi-bookmark-plus-outline mdi-rotate-90 </v-icon
                >СЛД централа
              </a>
            </v-list-item-title>
          </v-list-item>
          <v-list-item href="http://www.sld.org.rs/" target="_blank">
            <v-list-item-title>
              <a
                class="link"
                href="https://bolnicaprokuplje.com/"
                target="_blank"
                ><v-icon color="white" large left>
                  mdi-bookmark-plus-outline mdi-rotate-90 </v-icon
                >Општа болница Пк</a
              >
            </v-list-item-title>
          </v-list-item>
          <v-list-item href="http://www.sld.org.rs/" target="_blank">
            <v-list-item-title>
              <a
                class="link"
                href="https://bolnicaprokuplje.com/"
                target="_blank"
                ><v-icon color="white" large left>
                  mdi-bookmark-plus-outline mdi-rotate-90 </v-icon
                >Дом здравља Пк
              </a>
            </v-list-item-title>
          </v-list-item>
          <v-list-item href="http://www.sld.org.rs/" target="_blank">
            <v-list-item-title>
              <a class="link" href="https://www.dzblace.org.rs/" target="_blank"
                ><v-icon color="white" large left>
                  mdi-bookmark-plus-outline mdi-rotate-90 </v-icon
                >Дом здравља Блаце</a
              >
            </v-list-item-title>
          </v-list-item>
          <v-list-item href="http://www.sld.org.rs/" target="_blank">
            <v-list-item-title>
              <a
                class="link"
                href="http://www.dzzitoradja.com/2-vesti"
                target="_blank"
                ><v-icon color="white" large left>
                  mdi-bookmark-plus-outline mdi-rotate-90 </v-icon
                >Дом здравља Житорађа
              </a>
            </v-list-item-title>
          </v-list-item>
          <v-list-item href="http://www.sld.org.rs/" target="_blank">
            <v-list-item-title>
              <a
                class="link"
                href="https://www.zdravlje.gov.rs/"
                target="_blank"
                ><v-icon color="white" large left>
                  mdi-bookmark-plus-outline mdi-rotate-90 </v-icon
                >Министарство здравља</a
              >
            </v-list-item-title>
          </v-list-item>
          <v-list-item href="http://www.sld.org.rs/" target="_blank">
            <v-list-item-title>
              <a class="link" href="https://rlkjis.rs/" target="_blank"
                ><v-icon color="white" large left>
                  mdi-bookmark-plus-outline mdi-rotate-90 </v-icon
                >Регионална лекарска комора Ниш
              </a>
            </v-list-item-title>
          </v-list-item>
          <v-list-item href="http://www.sld.org.rs/" target="_blank">
            <v-list-item-title>
              <a class="link" href="http://www.lks.org.rs/" target="_blank"
                ><v-icon color="white" large left>
                  mdi-bookmark-plus-outline mdi-rotate-90 </v-icon
                >Лекарска комора Србије
              </a>
            </v-list-item-title>
          </v-list-item>
          <v-list-item href="http://www.sld.org.rs/" target="_blank">
            <v-list-item-title>
              <a class="link" href="http://www.kbs.co.rs/" target="_blank"
                ><v-icon color="white" large left>
                  mdi-bookmark-plus-outline mdi-rotate-90 </v-icon
                >Комора биохемичара
              </a>
            </v-list-item-title>
          </v-list-item>
          <v-list-item href="http://www.sld.org.rs/" target="_blank">
            <v-list-item-title>
              <a class="link" href="http://www.stomkoms.org/" target="_blank"
                ><v-icon color="white" large left>
                  mdi-bookmark-plus-outline mdi-rotate-90 </v-icon
                >Стоматолошка комора
              </a>
            </v-list-item-title>
          </v-list-item>
          <v-list-item href="http://www.sld.org.rs/" target="_blank">
            <v-list-item-title>
              <a
                class="link"
                href="https://bolnicaprokuplje.com/"
                target="_blank"
                ><v-icon color="white" large left>
                  mdi-bookmark-plus-outline mdi-rotate-90 </v-icon
                >Општа болница Пк</a
              >
            </v-list-item-title>
          </v-list-item>
          <v-list-item href="http://www.sld.org.rs/" target="_blank">
            <v-list-item-title>
              <a class="link" href="https://www.farmkom.rs/" target="_blank"
                ><v-icon color="white" large left>
                  mdi-bookmark-plus-outline mdi-rotate-90 </v-icon
                >Фармацеутска комора
              </a>
            </v-list-item-title>
          </v-list-item>
          <v-list-item href="http://www.sld.org.rs/" target="_blank">
            <v-list-item-title>
              <a
                class="link"
                href="http://www.zdravstvenisavetsrbije.gov.rs/"
                target="_blank"
                ><v-icon color="white" large left>
                  mdi-bookmark-plus-outline mdi-rotate-90 </v-icon
                >Здравствени савет Србије
              </a>
            </v-list-item-title>
          </v-list-item>
          <v-list-item href="http://www.sld.org.rs/" target="_blank">
            <v-list-item-title>
              <a
                class="link"
                href="https://medicinskapk.edu.rs/"
                target="_blank"
                ><v-icon color="white" large left>
                  mdi-bookmark-plus-outline mdi-rotate-90 </v-icon
                >Средња медицинска школа др Алекса Савић Прокупље
              </a>
            </v-list-item-title>
          </v-list-item>
          <v-list-item href="http://www.sld.org.rs/" target="_blank">
            <v-list-item-title>
              <a class="link" href="https://prokuplje.org.rs/" target="_blank"
                ><v-icon color="white" large left>
                  mdi-bookmark-plus-outline mdi-rotate-90 </v-icon
                >Град Прокупље
              </a>
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      src="./assets/nklinicki/10.jpg"
      prominent
      color="#4D81C0"
      dark
      shrink-on-scroll
      fade-img-on-scroll
    >
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          gradient="to top right, rgba(3, 165, 252), rgba(25,32,72,.7)"
          alt="sldprokuplje"
        ></v-img>
      </template>

      <div
        v-bind:class="isScroll ? 'smaller-logo' : ''"
        style="margin-right: 20px"
      >
        <v-img
          max-height="100"
          max-width="100"
          src="./assets/sldlogo.png"
          alt="sldprokuplje"
        >
        </v-img>
      </div>

      <v-app-bar-title style="width: 100%;align-self: center;" v-if="!$vuetify.breakpoint.mobile"
        ><h1>Српско Лекарско Друштво Прокупље</h1> </v-app-bar-title
      >

      <h3 v-if="$vuetify.breakpoint.mobile" class="d-flex align-self-end">
        СЛД Прокупље
      </h3>
      <v-spacer></v-spacer>
      <v-img
        alt="sldprokuplje"
        v-if="!$vuetify.breakpoint.mobile"
        max-height="100"
        max-width="100"
        src="./assets/logo-beli.png"
      >
      </v-img>
      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.mobile"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>

      <template v-if="!$vuetify.breakpoint.mobile" v-slot:extension>
        <v-tabs align-with-title>
          <v-tab to="/" tag="li">Почетна</v-tab>
          <!-- <v-tab to="/vesti">Вести</v-tab> -->
          <v-tab to="/about">О нама</v-tab>
          <v-tab to="/organization">Организација</v-tab>
          <v-tab to="/gallery">Галерија</v-tab>
          <v-tab to="/membership">Постани члан</v-tab>
          <v-tab to="/contact">Контакт</v-tab>
          <v-tab to="/aleksaSavic">Др Алекса Савић</v-tab>
          <v-tab href="https://www.sldprokuplje.rs/AMH/" target="_blank">Часопис</v-tab>
          <v-spacer></v-spacer>
          
          <v-tab v-if="!isScroll" to="/adminsld"><v-icon color="white" large left>
                  mdi-login </v-icon></v-tab>
        </v-tabs>
      </template>
      <!-- -->
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- Provides the application the proper gutter -->
     
        <!-- If using vue-router -->
        <router-view :style="!$vuetify.breakpoint.mobile ? 'padding: 0 6rem 0 6rem' : ''"></router-view>
    </v-main>

    <v-footer app dark padless>
      <v-row justify="center" no-gutters>
        <v-col class="py-4 text-center white--text" cols="12">
          {{ new Date().getFullYear() }} — <strong>СЛД Прокупље</strong>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>
<script>
export default {
  data: () => ({
    isScroll: false,
    scrollPosition: 0,
    drawer: true,
    group: null,
    articles: [],
    footerLinks: [
      "Почетна",
      "О нама",
      "Организација",
      "Галерија",
      "Чланство",
      "Контакт",
      "Алекса Савић",
      "Часопис",
    ],
  }),
  methods: {
    handleScroll() {
      this.scrollPosition = window.scrollY;
      if (this.scrollPosition >= 50) {
        this.isScroll = true;
      } else {
        this.isScroll = false;
      }
    },
  },
  created() {
    if (this.$vuetify.breakpoint.mobile) {
      this.drawer = false;
    }
    if (window.innerWidth < 900) {
      this.isSmallWidth = true;
    }
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<style>
#app {
  background: url("https://cdn.hipwallpaper.com/i/64/0/lYAuMy.png") no-repeat
    center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;  
  background-size: cover;
}
nav.app-drawer.primary.lighten-2.v-navigation-drawer.v-navigation-drawer--fixed.v-navigation-drawer--open.theme--light {
  max-height: 100%!important;
}
main.v-main {
  padding: 224px 0px 0px 256px;
}
.v-footer--fixed {
    position: initial!important;
}
.router-wrapper {
  background: url("https://cdn.hipwallpaper.com/i/64/0/lYAuMy.png");
  background-size: cover;
  background-repeat: repeat;
}
.v-app-bar-title__content {
  width: 100%;
}
.alignLogo {
  align-self: flex-end;
}
.smaller-logo {
  max-height: 50px;
  max-width: 50px;
}
.link {
  color: whitesmoke !important;
  text-decoration: none !important;
}
.list-wrapper {
  margin-top: 50px;
}
</style>