<template>
  <div>
    <h1 class="mb-5">ЧЛАНСТВО</h1>
    <hr />
    
    <div class="">
     
      <v-card
    class="mx-auto"
  >
    <div
      class="white--text align-end text-center justify-center"
      style="background-color: rgb(77, 129, 192) !important"
    >
      <v-card-title>Постаните члан СЛД Прокупље</v-card-title>
    </div>

    <v-card-text class="text--primary text-center">
      Преузимањем Приступнице можете постати наш члан
    </v-card-text>
    
    <iframe width="100%" height="500px" allow="autoplay" src="https://docs.google.com/document/d/e/2PACX-1vQGB7AQ8pSeIN82BArBvnhY2MjunOYycigrILFacEY_Ix_rQWIjxnHlx9X1Y_wapQ/pub?embedded=true"></iframe> 
    <v-card-actions class="text-center justify-center">
      <v-btn
        color="primary"
        text
        :href="pdfLink" download="Приступница"
      >Преузми Приступницу
      </v-btn>
    </v-card-actions>
  </v-card>
    </div>

    <!-- </div> -->
  </div>
</template>
<script>
export default {
  name: "Membership",

  data() {
    return {
      pdfLink: "https://drive.google.com/uc?export=download&id=1y8J30HYZiG3oTXPfU1urWWN7-_CaYznw",
    };
  },
};
</script>
<style scoped>
.resp-container {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
  height: 100vh;
}
.resp-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.card-wrapper {
  /* display: flex; */
  flex-direction: column;
  /* justify-content: ; */
  align-items: center;
}
.card-title {
  background-color: rgb(77, 129, 192) !important;
  width: 100%;
}
</style>


