import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Organization from '../views/Organization.vue'
import Gallery from '../views/Gallery.vue'
import Contact from '../views/Contact.vue'
import Member from '../views/Member.vue'
import AleksaSavic from '../views/AleksaSavic.vue'
import Magazine from '../views/Magazine.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/organization',
    name: 'Organization',
    component: Organization
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "organization" */ '../views/Organization.vue')
  },
  {
    path: '/gallery',
    name: 'Gallery',
    component: Gallery
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "gallery" */ '../views/Gallery.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
  }
  ,
  {
    path: '/membership',
    name: 'Member',
    component: Member,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "membership" */ '../views/Member.vue')
  },
  {
    path: '/aleksaSavic',
    name: 'AleksaSavic',
    component: AleksaSavic
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "aleksaSavic" */ '../views/AleksaSavic.vue')
  },
  {
    path: '/amh',
    name: 'Magazine',
    component: Magazine
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "aleksaSavic" */ '../views/AleksaSavic.vue')
  },
  {
    path: '/adminsld',
    name: 'admin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin.vue'),
    beforeEnter: (to, from, next) => {
      // ...
      var admin = prompt("Enter Password");
      if(admin == 'SldProkuplje') {
        next();
      } else {
        if(this.$router.currentRoute.path != "/") {
          router.go(-1)
        } else {
          router.go('/')
        }
      }
    }
  },
  {
    path :'*',
    redirect: Home,
    component: Home,
}
]

const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
