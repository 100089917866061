import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueGeolocation from 'vue-browser-geolocation'
import VueImg from 'v-img';
import vuetify from './plugins/vuetify';
import firebase from 'firebase/app';
import 'firebase/firestore';
import Vuetify from 'vuetify/lib'
 
Vue.config.productionTip = false
Vue.use(VueGeolocation)
Vue.use(VueImg);

// Your web app's Firebase configuration
// Initialize Firebase
firebase.initializeApp({
  apiKey: "AIzaSyBLV1e1FYYC-DsIaKOlqo7FZ-N7Ra46__I",
  authDomain: "sld-p-3b50f.firebaseapp.com",
  projectId: "sld-p-3b50f",
  storageBucket: "sld-p-3b50f.appspot.com",
  messagingSenderId: "1093560943516",
  appId: "1:1093560943516:web:18ed52619e5f335cb7be59"
});

export const db = firebase.firestore();

export default new Vuetify({
  breakpoint: {
    thresholds: {
      // xs: 340,
      // sm: 540,
      md: 1670,
      // lg: 1280,
    },
    scrollBarWidth: 24,
  },
})

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
