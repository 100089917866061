<template>
    <div>
        <h1>КОНТАКТ</h1>
        <hr/>
        <div v-bind:class="$vuetify.breakpoint.mobile ? 'contact-wrapper-mobile' : 'contact-wrapper'">
                <v-card
                    title="Српско лекарско друштво Прокупље"
                    img-src="../assets/nklinicki/1.jpg"
                    img-alt="Image"
                    img-top
                    alt="sldprokuplje"
                    tag="article"
                    class="mb-2 pa-2">
                    <v-card-text>
                    <h4>
                        Адреса:
                    </h4>
                    <p>Ћирила и Методија 4 18400 Прокупље</p>
                    <hr/>
                    <h4 >
                        E-mail:
                    </h4>
                    <p><a class="email" href = "mailto: office@sldprokuplje.rs">office@sldprokuplje.rs</a></p>
                    <hr/>
                    <h4>
                        Контакт телефон:
                    </h4>
                    <p>060/3764528</p>
                    <hr/>
                    <h4>
                        Председник:
                    </h4>
                    <p>Др Срђан Мијатовић</p>
                    <hr/>
                    <h4>
                        Потпредседник:
                    </h4>
                    <p>Др Братислав Васиљевић</p>
                    <hr/>
                    <h4>
                        Секретар:
                    </h4>
                    <p>Др Јулија Јовановић</p>
                    </v-card-text>
                </v-card>
                <v-card style="height: fit-content;"
                    class="mb-2 pa-2">
                    <HereMap  :center="center" />
                </v-card>
        </div>
        <div>
            <v-card class="mb-2 pa-2">
                <img width="100%" height="100%" src="../assets/memo.jpg" alt="" srcset="">
            </v-card>
        </div>
        
    </div>
</template>
<script>
import HereMap from '../components/HereMaps'
export default {
  name: 'app',
  components: {
    HereMap
    // Remove the HelloWorld.vue 
  },
  data() {
return {
    // we are this as prop to the HereMap component 
  center:{ 
    lat: 43.24485530687062, 
    lng: 21.5984692132037
    }
}
  
  }
}
</script>
<style scoped>
    a {
       color: black;
   }
    .contact-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        /* color: whitesmoke; */
    }
  .contact-wrapper-mobile {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
   }
</style>
